import ExternalLink, { LinkProps } from '@material-ui/core/Link';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Link as InternalLink } from 'gatsby';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles(theme =>
  createStyles({
    default: {
      textDecoration: 'underline',
      borderBottom: 'none',
      // '&:hover': {
      //   borderBottom: `solid ${theme.palette.secondary.main}`,
      // },
      '&:active': {
        color: theme.palette.primary.dark,
        borderBottom: `solid ${theme.palette.primary.dark}`,
      },
    },
    tooltip: {
      cursor: 'pointer',
      textDecoration: 'none',
      borderBottom: 'dashed',
      borderWidth: '1px !important',
      '&:hover': {
        borderBottom: `dashed ${theme.palette.secondary.main}`,
      },
      '&:active': {
        color: theme.palette.primary.dark,
        borderBottom: `dashed ${theme.palette.primary.dark}`,
      },
    },
    plain: {
      fontSize: theme.typography.h5.fontSize,
      textDecoration: 'none',
      borderBottom: 'none',
      color: theme.palette.primary.main,

      '&.MuiLink-root': {
        fontSize: theme.typography.h4.fontSize,
      },
    },
  }),
);

export interface TextLinkProps extends LinkProps {
  to?: string;
  external?: boolean;
  state?: Record<string, any>;
  plain?: boolean;
  textColor?: string;
  tooltip?: boolean;
  onClick?:
    | ((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void)
    | undefined;
  download?: string;
  tabIndex?: number;
}

const TextLink: FunctionComponent<TextLinkProps> = (props: TextLinkProps) => {
  const classes = useStyles(props);
  const className = props.tooltip
    ? classes.tooltip
    : props.plain
    ? classes.plain
    : classes.default;
  const onClick =
    props.to || props.onClick
      ? props.onClick
      : (e: React.MouseEvent) => e.preventDefault();

  if (props.external) {
    return (
      <ExternalLink
        tabIndex={props.tabIndex}
        className={className}
        target={props.target || '_blank'}
        href={props.to || '#'}
        onClick={onClick}
        download={props.download}
        component={'a'}
        style={props.style}
      >
        {props.children}
      </ExternalLink>
    );
  }

  return (
    <InternalLink
      tabIndex={props.tabIndex}
      to={(props.to as string) || '#'}
      className={className}
      onClick={onClick}
      style={props.style}
      state={props.state}
    >
      {props.children}
    </InternalLink>
  );
};

TextLink.defaultProps = {
  textColor: '#fff',
};

export default TextLink;
