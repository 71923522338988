import React from 'react';
import {
  Typography,
  Grid,
  createStyles,
  makeStyles,
  Theme,
  Container,
} from '@material-ui/core';
import { StaticImage } from 'gatsby-plugin-image';
import Link from '../components/text-link';
import ROUTES from '../routes';

export default function NotFoundPage() {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      imageWrapper: {
        [theme.breakpoints.down('xs')]: {
          display: 'flex',
          justifyContent: 'center',
        },
        [theme.breakpoints.down('md')]: {
          display: 'flex',
          justifyContent: 'center',
        },
      },
      links: {
        marginLeft: -20,
        '& li a': { fontSize: '1.3em' },
      },
    }),
  );
  const classes = useStyles();

  return (
    <Container
      style={{
        paddingTop: 50,
        paddingBottom: 50,
        textAlign: 'center',
      }}
    >
      <Grid container spacing={1} style={{ paddingTop: 20, paddingBottom: 50 }}>
        <Grid item xs={12} sm>
          <Typography variant={'h1'} style={{ fontWeight: 'bold' }}>
            Page not found
          </Typography>
          <Typography variant={'h2'} style={{ paddingTop: 20 }}>
            We're sorry, looks like we have misplaced the page you were looking
            for.
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ paddingTop: 10, paddingBottom: 50 }}>
        <Grid container item xs={12} sm>
          <Grid md={6} xs={12} item className={classes.imageWrapper}>
            <StaticImage
              src="../static/trees-in-the-pnw.png"
              alt="404"
              placeholder="tracedSVG"
              width={351}
              height={335}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              textAlign: 'start',
              paddingTop: 60,
              paddingLeft: 65,
            }}
          >
            <Typography variant={'h1'} style={{ textAlign: 'start' }}>
              Here are some helpful links:
            </Typography>
            <ul className={classes.links}>
              <li>
                <Link plain to={ROUTES.HELP}>
                  Help Center
                </Link>
              </li>
              <li>
                <Link plain to={ROUTES.CONTACT_US}>
                  Contact Us
                </Link>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
